
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuleData } from '../_services/module-data';
import { NavigationService } from '../_services/navigation.service';


@Component({
  selector: 'app-generator-change',
  // standalone: true,
  // imports: [],
  templateUrl: './generator-change.component.html',
  styleUrl: './generator-change.component.scss'
})
export class GeneratorChangeComponent implements OnInit {
  newModule: ModuleData;
  typeCode: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialogRef: MatDialogRef<GeneratorChangeComponent>,
              private navigationService: NavigationService) {
    this.newModule = data.newModule;
    this.typeCode = data.typeCode;
  }

  ngOnInit(): void {
    console.log('############# newModule => ' + this.newModule);   
    console.log('############# newModule.moduleId => ' + this.newModule.moduleId);
  }
  
  public onOpenGenerator(moduleId: string) {
    console.log('####### onOpenGenerator(), moduleId => ' + moduleId);   
    console.log('####### onOpenGenerator(), typeId => ' + this.typeCode);
    this.navigationService.navigate('/generator',{ 
      moduleId: this.typeCode, 
      replaceInHistory: false, 
      reloadRoute: true });
    this.dialogRef.close();
  }

  public onKeydown(event: KeyboardEvent, moduleId: string): void {
    if (event.key === 'Enter') {
        this.onOpenGenerator(moduleId);
    }
}

  public clickCancel(): void {
    this.dialogRef.close();
  }
}
