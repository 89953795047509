import { BrowserModule } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { HttpBackend, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { DatePipe } from '@angular/common';

import { BciCoreModule, BciLayoutModule, BciSharedModule, BciImprintComponent, BCI_SHARED_INTL_PROVIDER } from '@bci-web-core/core';

import { CoreModule } from '@rexroth/core';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { GeneratorComponent } from './generator/generator.component';
import { OperationHelpComponent } from './operation-help/operation-help.component';
import { TypeCodeMatNumberValidatorDirective } from './_directives/typecodevalidator.directive';
import { LoggingInterceptor } from './logging.interceptor';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { HelpMenuComponent } from './help-menu/help-menu.component';
import { AboutComponent } from './about/about.component';
import { GeneratorChangeComponent } from './generator-change/generator-change.component';

export function createMultiTranslateHttpLoader(http: HttpBackend): TranslateLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/core.', suffix: '.json' },
    { prefix: './assets/i18n/generator.', suffix: '.json' },
    // { prefix: './assets/i18n/identity-manager.', suffix: '.json' },
  ]);
}

@NgModule({ declarations: [
        AppComponent,
        GeneratorComponent,
        OperationHelpComponent,
        TypeCodeMatNumberValidatorDirective,
        UserMenuComponent,
        HelpMenuComponent,
        AboutComponent,
        GeneratorChangeComponent
    ],
    bootstrap: [AppComponent] //,
    // entryComponents: [
    //   BciImprintComponent,
    //   OperationHelpComponent
    // ]
    , imports: [CoreModule,
        BrowserModule,
        ScrollingModule,
        AppRoutingModule,
        MaterialModule,
        FormsModule,
        BciCoreModule.forRoot({ prod_environment: environment.production, core_config_url: '/assets/config/config.json' }),
        BciLayoutModule,
        BciSharedModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createMultiTranslateHttpLoader,
                deps: [HttpBackend]
            },
            defaultLanguage: 'en_US'
        }),
        LoggerModule.forRoot({
            level: getLogLevel(),
            timestampFormat: 'HH:mm:ss.SSS',
            disableConsoleLogging: false,
            proxiedSteps: 2,
            disableFileDetails: environment.disableFileDetails,
            enableSourceMaps: environment.enableSourceMaps,
            serverLogLevel: environment.serverLogLevel //,
            //serverLoggingUrl: environment.serverLoggingUrl
        })], providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggingInterceptor,
            multi: true,
        },
        BCI_SHARED_INTL_PROVIDER,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

function getLogLevel(): NgxLoggerLevel {
  const loggerLevel = sessionStorage.getItem('logger');
  if (loggerLevel) {
    switch (loggerLevel) {
      case 'trace':
        return NgxLoggerLevel.TRACE;
      case 'debug':
        return NgxLoggerLevel.DEBUG;
      case 'info':
        return NgxLoggerLevel.INFO;
      case 'warning':
        return NgxLoggerLevel.WARN;
      default:
        return NgxLoggerLevel.ERROR;
    }
  } else {
    return environment.logLevel ? environment.logLevel : NgxLoggerLevel.ERROR;
  }
}
