<bci-page-content class="full-space padding" cdkScrollable (keydown)="onKeydown($event, newModule.moduleId)">
    <div id="flexBoxCard" class="flex-container">
        <div class="dialog-header">
            <h3 matDialogTitle translate>_GenChgHeadline</h3>
            <p><span translate>_GenChgText1</span><br><strong>{{ newModule.moduleId }}**, {{ newModule.title }}</strong></p>
            <p><span translate>_GenChgText2</span></p>
            <button class="close-button" mat-icon-button tabindex="0" (click)="clickCancel()">
            <i class="Bosch-Ic Bosch-Ic-abort"></i>
            </button>
        </div>
        <div class="panel-container">
            <mat-card #productCard bciCardColorState="blue" (click)="onOpenGenerator(newModule.moduleId)" [bciCardHoverPressed]="true" 
                appearance="outlined" class="product-card" role="region">
                <mat-card-header class="product-card-header">
                    <mat-card-title class="product-card-title">{{ newModule.moduleId }}**</mat-card-title>
                    <mat-card-subtitle>{{ newModule.title }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="product-card-content">
                    <img class="product-card-image" mat-card-image [src]="newModule.image">
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</bci-page-content>